<script lang="ts" setup>
const { open } = useNlcModals();
const { data } = useAppInitData();
const isLogin = computed(() => data.value?.isGuest === false);
const valueProgress = computed(() => data.value?.season?.totalProgressPercents || 0);

const handleOpenModalClick = () => {
	isLogin.value ? open("LazyOModalSpookySeasonTower") : open("LazyOModalSignup");
};
</script>

<template>
	<div class="pumpkin-btn" @click="handleOpenModalClick">
		<NuxtImg
			class="pumpkin"
			src="/nuxt-img/seasons/halloween/pumpkin-progress.png"
			loading="lazy"
			format="webp"
			alt="progress"
			width="48"
			height="36"
		/>
		<AProgressCircle class="pumpkin-progress" :value="valueProgress" linecap="round" />
	</div>
</template>

<style lang="scss" scoped>
.pumpkin-btn {
	position: absolute;
	top: 0;
	left: 0;
	width: 48px;
	height: 48px;
	background: var(--custom-12);
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 99;
	cursor: pointer;
}
.pumpkin-progress {
	position: absolute;
	top: -10px;
	left: -10px;
}
</style>
